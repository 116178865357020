
// Write your custom style. Feel free to split your code to several files
body {
  color: #191919;
}

/*
.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
*/


.green-overlay[data-overlay]::before, 
.green-overlay[data-overlay-light]::before {
    background: #224100;
}

.timeline-item {
	padding-top: 0;
	padding-bottom: 0;
}

.timeline-item.vertical-offset{
	margin-top: -130px;
}
@media (max-width: 767.98px){
	.timeline-item.vertical-offset{
		margin-top: 0px;
	}
}

.slick-slider.rounded .slick-list {
	border-radius: .25rem !important;
}


ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}


